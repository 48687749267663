import photoPerson from './assets/images/person.webp';
import photoBab from './assets/images/s5/players/bab.webp';
import photoCrow from './assets/images/s5/players/crow.webp';
import photoIzu from './assets/images/s5/players/izu.webp';
import photoMasicko from './assets/images/s5/players/masicko.webp';
import photoMetodman from './assets/images/s5/players/metodman.webp';
import photoMoses from './assets/images/s5/players/moses.webp';
import photoSatik from './assets/images/s5/players/satik.webp';
import photoSkophek from './assets/images/s5/players/skophek.webp';
import photoStriker from './assets/images/s5/players/striker.webp';
import photoSyrax from './assets/images/s5/players/syrax.webp';
import photoVaclavson from './assets/images/s5/players/vaclavson.webp';
import photoWlad from './assets/images/s5/players/wlad.webp';
import { PlayerType } from './types/players';

export const players = {
  MetodMan: {
    nick: 'MetodMan',
    name: 'Alexey Afanasyev',
    race: 'Protoss',
    image: photoMetodman,
  } as PlayerType,
  BaB: {
    nick: 'BaB',
    name: 'Marek Šťastný',
    race: 'Zerg',
    image: photoBab,
  } as PlayerType,
  Masicko: {
    nick: 'Masicko',
    name: 'Vojtěch Miloš',
    race: 'Protoss',
    image: photoMasicko,
  } as PlayerType,
  Striker: {
    nick: 'Striker',
    name: 'Jaromír Červený',
    race: 'Terran',
    image: photoStriker,
  } as PlayerType,
  izu: {
    nick: 'izu',
    name: 'Ivo Chlumecký',
    race: 'Zerg',
    image: photoIzu,
  } as PlayerType,
  Syrax: {
    nick: 'Syrax',
    name: 'Alexandr Bílý',
    race: 'Zerg',
    image: photoSyrax,
  } as PlayerType,
  Crow: {
    nick: 'Crow',
    name: 'Jakub Sedláček',
    race: 'Zerg',
    image: photoCrow,
  } as PlayerType,
  Vaclavson: {
    nick: 'Vaclavson',
    name: 'Václav Špéra',
    race: 'Terran',
    image: photoVaclavson,
  } as PlayerType,
  Satik: {
    nick: 'Satik',
    name: 'Luboš Běhounek',
    race: 'Zerg',
    image: photoSatik,
  } as PlayerType,
  Moses: {
    nick: 'Moses',
    name: 'Marek Hon',
    race: 'Protoss',
    image: photoMoses,
  } as PlayerType,
  sKopheK: {
    nick: 'sKopheK',
    name: 'Libor Pečinka',
    race: 'Random',
    image: photoSkophek,
  } as PlayerType,
  Wlad: {
    nick: 'Wlad',
    name: 'Jan Gulčík',
    race: 'Protoss',
    image: photoWlad,
  } as PlayerType,
  unknown: {
    nick: '?',
    image: photoPerson,
  },
} as const;
