import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import styles from './LinkList.module.scss';

type LinkListProps = {
  items: {
    name: ReactNode;
    to: string;
    className?: string;
    target?: string;
  }[];
};

const LinkList: FC<LinkListProps> = ({ items }) => {
  return (
    <div className={styles.linkWrapper}>
      {items.map(({ to, name, className, target }, i) => (
        <Link to={to} className={clsx(styles.link, className)} key={i} target={target}>
          {name}
        </Link>
      ))}
    </div>
  );
};

export default LinkList;
