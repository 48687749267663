// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jZT6gymFzkODDzJWQ9lm {
  padding: 5vh 5vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90vw, 1fr));
  column-gap: 5vw;
}
@media only screen and (min-width: 768px) {
  .jZT6gymFzkODDzJWQ9lm {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/match.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAIA;EACE,gBAAA;EACA,aAAA;EACA,0DAAA;EACA,eAAA;AAHF;ACwBU;EDzBV;IAOI,2DAAA;EAFF;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"matchList": `jZT6gymFzkODDzJWQ9lm`
};
export default ___CSS_LOADER_EXPORT___;
