import { players as playersData } from '../../players';
import { PlayerDetailType } from '../../types/players';
import score from '../../data/s5/stats.json';
import { parseStats } from '../../utils';

export const players = {
  MetodMan: {
    ...playersData.MetodMan,
    rank: 2,
    stats: parseStats(score.MetodMan),
  } as PlayerDetailType,
  BaB: {
    ...playersData.BaB,
    rank: 3,
    stats: parseStats(score.BaB),
  } as PlayerDetailType,
  Masicko: {
    ...playersData.Masicko,
    rank: 7,
    stats: parseStats(score.Masicko),
  } as PlayerDetailType,
  Striker: {
    ...playersData.Striker,
    rank: 9,
    stats: parseStats(score.Striker),
  } as PlayerDetailType,
  izu: {
    ...playersData.izu,
    rank: 1,
    stats: parseStats(score.izu),
  } as PlayerDetailType,
  Syrax: {
    ...playersData.Syrax,
    rank: 4,
    stats: parseStats(score.Syrax),
  } as PlayerDetailType,
  Crow: {
    ...playersData.Crow,
    rank: 5,
    stats: parseStats(score.Crow),
  } as PlayerDetailType,
  Vaclavson: {
    ...playersData.Vaclavson,
    rank: 11,
    stats: parseStats(score.Vaclavson),
  } as PlayerDetailType,
  Satik: {
    ...playersData.Satik,
    rank: 6,
    stats: parseStats(score.Satik),
  } as PlayerDetailType,
  Moses: {
    ...playersData.Moses,
    rank: 10,
    stats: parseStats(score.Moses),
  } as PlayerDetailType,
  sKopheK: {
    ...playersData.sKopheK,
    rank: 8,
    stats: parseStats(score.sKopheK),
  } as PlayerDetailType,
  Wlad: {
    ...playersData.Wlad,
    rank: 12,
    stats: parseStats(score.Wlad),
  } as PlayerDetailType,
} as const;
