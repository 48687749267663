import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Layout from './Layout';
import { Routes as Pages } from './routes';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          {Object.values(Pages).flatMap(({ path, component }) => (
            <Route key={path} path={path} element={component} />
          ))}
          <Route index element={<Navigate to={Pages.S5_FINALS.path} replace />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
