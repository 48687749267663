import { Finals } from './pages/s5/Finals';
import { GroupA } from './pages/s5/GroupA';
import { GroupB } from './pages/s5/GroupB';
import { Playoff } from './pages/s5/Playoff';
import { QualificationGroupBlue } from './pages/s5/QualificationGroupBlue';
import { QualificationGroupRed } from './pages/s5/QualificationGroupRed';

const SEASON_5_BASE = '/5-sezona';
const PRELIMINARY_BASE = `${SEASON_5_BASE}/predkolo-finale`;

export const Routes = Object.freeze({
  S5_FINALS: {
    path: `${SEASON_5_BASE}/offline-finale`,
    component: <Finals />,
    index: true,
  },
  S5_FINALS_GROUP_A: {
    path: `${SEASON_5_BASE}/offline-finale/skupina-a`,
    component: <GroupA />,
  },
  S5_FINALS_GROUP_B: {
    path: `${SEASON_5_BASE}/offline-finale/skupina-b`,
    component: <GroupB />,
  },
  S5_FINALS_PLAYOFF: {
    path: `${SEASON_5_BASE}/offline-finale/play-off`,
    component: <Playoff />,
  },
  S5_PRELIMINARY_RED: {
    path: `${PRELIMINARY_BASE}/red-group`,
    component: <QualificationGroupRed />,
  },
  S5_PRELIMINARY_BLUE: {
    path: `${PRELIMINARY_BASE}/blue-group`,
    component: <QualificationGroupBlue />,
  },
  S5_ROOKIE_TOUR: {
    path: `${SEASON_5_BASE}/rookie-tour`,
    component: <></>,
  },
  S5_GROUP_STAGE: {
    path: `${SEASON_5_BASE}/zakladni-cast`,
    component: <></>,
  },
});
