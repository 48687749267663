// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QWcsfLSbHAzPxQnyMFHo {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 7rem auto 10rem;
  align-items: center;
  max-width: 70vw;
  position: relative;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .QWcsfLSbHAzPxQnyMFHo {
    flex-direction: row;
    justify-content: center;
    max-width: 90vw;
  }
}
@media only screen and (min-width: 1280px) {
  .QWcsfLSbHAzPxQnyMFHo {
    max-width: 1000px;
  }
}

.U3EO7zGxbtAQ3AIXGe7f {
  padding: 1rem 1.5rem 1.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  clip-path: polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px);
  background-image: radial-gradient(ellipse at top, var(--button-link-bg-color1), var(--button-link-bg-color2));
  border: 1px solid transparent;
  text-transform: uppercase;
  font-family: var(--font-cool);
  font-size: 2.2rem;
}
@media only screen and (min-width: 1280px) {
  .U3EO7zGxbtAQ3AIXGe7f {
    opacity: 0.9;
    transition: opacity 0.4s, border-color 0.4s;
    font-size: 3rem;
    max-width: 300px;
  }
}
.U3EO7zGxbtAQ3AIXGe7f:hover, .U3EO7zGxbtAQ3AIXGe7f:focus {
  text-decoration: none;
  border-color: var(--button-link-border-color);
}
@media only screen and (min-width: 1280px) {
  .U3EO7zGxbtAQ3AIXGe7f:hover, .U3EO7zGxbtAQ3AIXGe7f:focus {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LinkList.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;AAHF;ACoBU;EDzBV;IAWI,mBAAA;IACA,uBAAA;IACA,eAAA;EAFF;AACF;ACaU;EDzBV;IAiBI,iBAAA;EADF;AACF;;AAIA;EACE,2BAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;EAEA,kGAAA;EACA,6GAAA;EACA,6BAAA;EACA,yBAAA;EACA,6BAAA;EACA,iBAAA;AAFF;ACNU;EDJV;IAeI,YAAA;IACA,2CACE;IAEF,eAAA;IACA,gBAAA;EAHF;AACF;AAKE;EAEE,qBAAA;EACA,6CAAA;AAJJ;AClBU;EDmBR;IAMI,UAAA;EAHJ;AACF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkWrapper": `QWcsfLSbHAzPxQnyMFHo`,
	"link": `U3EO7zGxbtAQ3AIXGe7f`
};
export default ___CSS_LOADER_EXPORT___;
