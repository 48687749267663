// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JxWGn_7uQ8_EsdtCu25r {
  --group-title-color: #d9af6d;
  --group-title-border-color: #f8d596;
  --bg-text-color: #d9af6d;
  --player-bg-color: #856f4b;
  --player-bg-color-under: #766446;
  --player-content-bg: #d9af6d;
  --match-color: #d9af6d;
  --match-score-color: #000f45;
  --match-player-color: #000f45;
  --subheading-color: #d9af6d;
  --match-score-winner-color: #766446;
  --button-link-bg-color1: #ce9945;
  --button-link-bg-color2: #896224;
  --button-link-border-color: #f8d596;
}

.lhgz3BEbTD6NuqWXcYFZ {
  --group-title-color: #ef284b;
  --group-title-border-color: #dd8091;
  --bg-text-color: #ef284b;
  --player-bg-color: #7a2334;
  --player-bg-color-under: #87303f;
  --player-content-bg: #ef284b;
  --match-color: #ef284b;
  --subheading-color: #ef284b;
  --match-score-winner-color: #ef284b;
  --players-advances-color: #ffd9a4;
}

.xmFuf8PaWgE24VtoX3Lz {
  --group-title-color: #0085fe;
  --group-title-border-color: #5bb1ff;
  --bg-text-color: #0085fe;
  --player-bg-color: #0b508f;
  --player-bg-color-under: #0a569d;
  --player-content-bg: #0085fe;
  --match-color: #0085fe;
  --subheading-color: #0085fe;
  --match-score-winner-color: #0085fe;
  --players-advances-color: #ffd9a4;
}

.zK5TWAqAY84KnTQ9R8i0 {
  max-width: 1600px;
  margin: 0 auto;
}

.Nfb6Y1dhhnm1cC9_w2Nt {
  position: relative;
  z-index: 1;
}

.wtCKDBsMXDaX3TpXO3su {
  line-height: 1.5;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 auto 2rem;
  max-width: 90vw;
  clip-path: polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px);
}
@media only screen and (min-width: 1280px) {
  .wtCKDBsMXDaX3TpXO3su {
    margin-top: 5rem;
    margin-bottom: 0;
    text-align: center;
    max-width: 1280px;
  }
}
.wtCKDBsMXDaX3TpXO3su p + p {
  margin-top: 1rem;
}

@media only screen and (max-width: 1279.9px) {
  .YSCe11rZsaHqjxAgq4BI {
    text-align: left;
  }
}

.y4DenLSGqVRrqJ23FSUw {
  margin-bottom: 5rem;
}

.s8mv0uEKhZirTmaO1yG6 {
  margin: 5rem auto 2rem;
}
@media only screen and (min-width: 1280px) {
  .s8mv0uEKhZirTmaO1yG6 {
    max-width: 1200px;
  }
}

.lqfh0gUkFHaMvmxGFOOq {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  margin: 5rem auto 2rem;
  max-width: 90vw;
}

.zulLnUQCHty5UA1G2tvC {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/s5/styles.module.scss","webpack://./src/styles/mixins/_media.scss"],"names":[],"mappings":"AAOA;EACE,4BAAA;EACA,mCAAA;EACA,wBAAA;EACA,0BAAA;EACA,gCAAA;EACA,4BAAA;EACA,sBAAA;EACA,4BAAA;EACA,6BAAA;EACA,2BAAA;EACA,mCAAA;EACA,gCAAA;EACA,gCAAA;EACA,mCAAA;AANF;;AASA;EAGE,4BAAA;EACA,mCAAA;EACA,wBAAA;EACA,0BAAA;EACA,gCAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,mCAAA;EACA,iCAAA;AARF;;AAWA;EAGE,4BAAA;EACA,mCAAA;EACA,wBAAA;EACA,0BAAA;EACA,gCAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,mCAAA;EACA,iCAAA;AAVF;;AAaA;EACE,iBAAA;EACA,cAAA;AAVF;;AAaA;EAEE,kBAAA;EACA,UAAA;AAXF;;AAcA;EACE,gBAAA;EACA,aAAA;EACA,oCAAA;EACA,mBAAA;EACA,eAAA;EAGA,kGAAA;AAbF;AC/BU;EDoCV;IAWI,gBAAA;IACA,gBAAA;IACA,kBAAA;IACA,iBAAA;EAZF;AACF;AAcE;EACE,gBAAA;AAZJ;;AC1BY;ED0CZ;IAEI,gBAAA;EAbF;AACF;;AAgBA;EACE,mBAAA;AAbF;;AAgBA;EACE,sBAAA;AAbF;ACxDU;EDoEV;IAKI,iBAAA;EAbF;AACF;;AAgBA;EACE,kBAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;AAbF;;AAgBA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAbF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `JxWGn_7uQ8_EsdtCu25r`,
	"redColors": `lhgz3BEbTD6NuqWXcYFZ`,
	"blueColors": `xmFuf8PaWgE24VtoX3Lz`,
	"content": `zK5TWAqAY84KnTQ9R8i0`,
	"container": `Nfb6Y1dhhnm1cC9_w2Nt`,
	"description": `wtCKDBsMXDaX3TpXO3su`,
	"subheading": `YSCe11rZsaHqjxAgq4BI`,
	"subheadingMarginBottom": `y4DenLSGqVRrqJ23FSUw`,
	"videoWrapper": `s8mv0uEKhZirTmaO1yG6`,
	"iframeWrapper": `lqfh0gUkFHaMvmxGFOOq`,
	"video": `zulLnUQCHty5UA1G2tvC`
};
export default ___CSS_LOADER_EXPORT___;
